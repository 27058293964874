import {CURRENT_GUILD_TAG, guildAPI} from '../../guild/rtkGuildApi'
import {IdType} from '../../util/models/IdType'
import {AddFreeTextAttributeRequestDTO} from '../model/private/AddFreeTextAttributeRequestDTO'
import {UpdateFreeTextAttributeRequestDTO} from '../model/private/UpdateFreeTextAttributeRequestDTO'

import {DefaultServiceRequestCreationDTO, FileDTO, ServiceRequest} from '../model/ServiceRequest'
import {MonitoredBundleMonitoringServiceRequestDTO} from './monitoredBundle/MonitoredBundleMonitoringServiceRequestDTO'
import {setSnackbarMessage} from '../../genericComponents/commonSlice'
import {CADModelServiceRequestDTO} from './cadModelCreation/CADModelServiceRequestDTO'
import {ExtractionFrequencyEnum} from '../model/ExtractionFrequencyEnum'

import {saveFileFromBlobStorage, uploadServiceRequestFileToBlobStorage} from './serviceRequestAsyncActions'
import {
    InformationPackageAccessServiceRequestForListDTO,
    ItemInformationPackageAccessServiceRequestForItemDTO
} from './accessToInformationPackage/InformationPackageAccessServiceRequestDTO'
import {PartExtractionServiceRequestDTO} from './partExtraction/Forms/PartExtractionServiceRequestDTO'
import {CreatePrivateArtifactRequestDTO} from '../model/private/CreatePrivateArtifactRequestDTO'
import {UpdatePrivateArtifactRequestDTO} from '../model/private/UpdatePrivateArtifactRequestDTO'

const SERVICE_REQUESTS_TAG = 'ServiceRequests'
const SERVICE_REQUEST_ERROR_MESSAGE = 'Error in creating Service Request. Please try again.'

export const generateErrorMessage = (
    response: {
        data: {errorMessage: string}
        status: number
    },
    fallback: string = SERVICE_REQUEST_ERROR_MESSAGE
): string => {
    if (response?.status !== 400) return fallback
    return response?.data?.errorMessage ?? fallback
}

export type GenericRequestResponse<T> = {
    message: string
    data: T
}

export const serviceRequestApi = guildAPI
    .enhanceEndpoints({
        addTagTypes: [SERVICE_REQUESTS_TAG]
    })
    .injectEndpoints({
        endpoints: (builder) => {
            return {
                createDefaultServiceRequest: builder.mutation<any, DefaultServiceRequestCreationDTO>({
                    queryFn: async (
                        {guildId, service, file, items, numberOfItems, workflowConfigurationId},
                        {dispatch},
                        extraOptions,
                        baseQuery
                    ) => {
                        try {
                            const serviceRequestResult = await baseQuery({
                                url: 'serviceRequests',
                                method: 'POST',
                                data: {guildId, service, fileName: file.name, size: file.size, items, numberOfItems, workflowConfigurationId: workflowConfigurationId }
                            })

                            const createdServiceRequest = (
                                serviceRequestResult.data as GenericRequestResponse<ServiceRequest>
                            ).data

                            await uploadServiceRequestFileToBlobStorage(createdServiceRequest.file, file)

                            return {data: (serviceRequestResult.data as GenericRequestResponse<ServiceRequest>).data}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage(generateErrorMessage(error?.response)))
                            return {error: error?.response}
                        }
                    }
                }),
                getGuildServiceRequests: builder.query<any, IdType | undefined>({
                    query: (guildId) => ({
                        url: `serviceRequests/guild/${guildId}`,
                        method: 'GET'
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) =>
                        generateErrorMessage(response, 'Internet error. Please try again.'),
                    providesTags: [SERVICE_REQUESTS_TAG]
                }),
                getGuildServiceRequestsForApprover: builder.query<any, IdType | undefined>({
                    query: (guildId) => ({
                        url: `serviceRequests/guild/${guildId}/approver`,
                        method: 'GET'
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) =>
                        generateErrorMessage(response, 'Internet error. Please try again.'),
                    providesTags: [SERVICE_REQUESTS_TAG]
                }),
                getUserServiceRequests: builder.query<any, IdType | undefined>({
                    query: (guildId) => ({
                        url: `serviceRequests/guild/${guildId}/me`,
                        method: 'GET'
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) =>
                        generateErrorMessage(response, 'Internet error. Please try again.'),
                    providesTags: [SERVICE_REQUESTS_TAG]
                }),
                acceptQuotation: builder.mutation<any, IdType>({
                    query: (serviceRequestId) => ({
                        url: `serviceRequests/serviceRequest/${serviceRequestId}/quotation/accept`,
                        method: 'PATCH'
                    }),
                    invalidatesTags: [CURRENT_GUILD_TAG, SERVICE_REQUESTS_TAG],
                    transformErrorResponse: (response: any) =>
                        response?.data?.errorMessage || 'Something went wrong when accepting quotation'
                }),
                rejectQuotation: builder.mutation<any, IdType>({
                    query: (serviceRequestId) => ({
                        url: `serviceRequests/serviceRequest/${serviceRequestId}/quotation/reject`,
                        method: 'PATCH'
                    }),
                    invalidatesTags: [CURRENT_GUILD_TAG, SERVICE_REQUESTS_TAG]
                }),
                acceptServiceRequest: builder.mutation({
                    query: (serviceRequestId) => ({
                        url: `serviceRequests/serviceRequest/${serviceRequestId}/status/accept`,
                        method: 'PATCH'
                    }),
                    invalidatesTags: [SERVICE_REQUESTS_TAG]
                }),
                rejectServiceRequest: builder.mutation({
                    query: (serviceRequestId) => ({
                        url: `serviceRequests/serviceRequest/${serviceRequestId}/status/reject`,
                        method: 'PATCH'
                    }),
                    invalidatesTags: [SERVICE_REQUESTS_TAG]
                }),
                createItemMonitoringServiceRequest: builder.mutation<any, MonitoredBundleMonitoringServiceRequestDTO>({
                    query: (serviceRequest) => ({
                        url: 'serviceRequests/monitored-bundle',
                        method: 'POST',
                        data: serviceRequest
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) => generateErrorMessage(response)
                }),
                createAccessToInformationPackageServiceRequestForList: builder.mutation<
                    any,
                    InformationPackageAccessServiceRequestForListDTO
                >({
                    query: (serviceRequest) => ({
                        url: 'serviceRequests/access-information-package/list',
                        method: 'POST',
                        data: serviceRequest
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) => generateErrorMessage(response)
                }),
                createAccessToInformationPackageServiceRequestForItem: builder.mutation<
                    any,
                    ItemInformationPackageAccessServiceRequestForItemDTO
                >({
                    query: (serviceRequest) => ({
                        url: 'serviceRequests/access-information-package/item',
                        method: 'POST',
                        data: serviceRequest
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) => generateErrorMessage(response)
                }),
                createCADModelServiceRequest: builder.mutation<any, CADModelServiceRequestDTO>({
                    query: (serviceRequest) => ({
                        url: 'serviceRequests/cad-model/list',
                        method: 'POST',
                        data: serviceRequest
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) => generateErrorMessage(response)
                }),
                updateFreeTextServiceRequest: builder.mutation<any, UpdateFreeTextAttributeRequestDTO>({
                    queryFn: async (
                        {guildId, newValueByItems, deleteAttributesOnItems, label, file, attributeDefinitionId, workflowConfigurationId},
                        {dispatch},
                        extraOptions,
                        baseQuery
                    ) => {
                        try {
                            const serviceRequestResult = await baseQuery({
                                url: 'serviceRequests/private-attributes/free-text/update',
                                method: 'POST',
                                data: {
                                    guildId,
                                    newValueByItems,
                                    deleteAttributesOnItems,
                                    label,
                                    attributeDefinitionId,
                                    fileName: file.name,
                                    size: file.size,
                                    workflowConfigurationId
                                }
                            })

                            const createdServiceRequest = (
                                serviceRequestResult.data as GenericRequestResponse<ServiceRequest>
                            ).data

                            await uploadServiceRequestFileToBlobStorage(createdServiceRequest.file, file)

                            return {data: (serviceRequestResult.data as GenericRequestResponse<ServiceRequest>).data}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage(generateErrorMessage(error?.response)))
                            return {error: error?.response}
                        }
                    }
                }),

                createFreeTextServiceRequest: builder.mutation<any, AddFreeTextAttributeRequestDTO>({
                    queryFn: async ({label, newValueByItems, guildId, file, workflowConfigurationId}, {dispatch}, extraOptions, baseQuery) => {
                        try {
                            const serviceRequestResult = await baseQuery({
                                url: 'serviceRequests/private-attributes/free-text',
                                method: 'POST',
                                data: {label, newValueByItems, guildId, fileName: file.name, size: file.size, workflowConfigurationId: workflowConfigurationId}
                            })

                            const createdServiceRequest = (
                                serviceRequestResult.data as GenericRequestResponse<ServiceRequest>
                            ).data

                            await uploadServiceRequestFileToBlobStorage(createdServiceRequest.file, file)

                            return {data: (serviceRequestResult.data as GenericRequestResponse<ServiceRequest>).data}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage(generateErrorMessage(error?.response)))
                            return {error: error?.response}
                        }
                    }
                }),
                createPrivateArtifactServiceRequest: builder.mutation<any, CreatePrivateArtifactRequestDTO>({
                    queryFn: async (
                        {label, itemList, guildId, artifactFile, file, workflowConfigurationId},
                        {dispatch},
                        extraOptions,
                        baseQuery
                    ) => {
                        try {
                            const serviceRequestResult = await baseQuery({
                                url: 'serviceRequests/private-attributes/artifact',
                                method: 'POST',
                                data: {
                                    label: label,
                                    guildId: guildId,
                                    itemListId: itemList?.id,
                                    artifactFileName: artifactFile.name,
                                    artifactSize: artifactFile.size,
                                    fileName: file.name,
                                    size: file.size,
                                    workflowConfigurationId: workflowConfigurationId,
                                }
                            })

                            const createdServiceRequest = (
                                serviceRequestResult.data as GenericRequestResponse<ServiceRequest>
                            ).data

                            await uploadServiceRequestFileToBlobStorage(
                                createdServiceRequest.artifactFile,
                                artifactFile
                            )
                            await uploadServiceRequestFileToBlobStorage(createdServiceRequest.file, file)

                            return {data: (serviceRequestResult.data as GenericRequestResponse<ServiceRequest>).data}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage(generateErrorMessage(error?.response)))
                            return {error: error?.response}
                        }
                    }
                }),
                updatePrivateArtifactServiceRequest: builder.mutation<any, UpdatePrivateArtifactRequestDTO>({
                    queryFn: async (
                        {label, itemList, guildId, artifactFile, file, attributeDefinitionId, workflowConfigurationId},
                        {dispatch},
                        extraOptions,
                        baseQuery
                    ) => {
                        try {
                            const serviceRequestResult = await baseQuery({
                                url: 'serviceRequests/private-attributes/artifact/update',
                                method: 'POST',
                                data: {
                                    label: label,
                                    guildId: guildId,
                                    attributeDefinitionId: attributeDefinitionId,
                                    itemListId: itemList?.id,
                                    artifactFileName: artifactFile.name,
                                    artifactSize: artifactFile.size,
                                    fileName: file.name,
                                    size: file.size,
                                    workflowConfigurationId
                                }
                            })

                            const createdServiceRequest = (
                                serviceRequestResult.data as GenericRequestResponse<ServiceRequest>
                            ).data

                            await uploadServiceRequestFileToBlobStorage(
                                createdServiceRequest.artifactFile,
                                artifactFile
                            )
                            await uploadServiceRequestFileToBlobStorage(createdServiceRequest.file, file)

                            return {data: (serviceRequestResult.data as GenericRequestResponse<ServiceRequest>).data}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage(generateErrorMessage(error?.response)))
                            return {error: error?.response}
                        }
                    }
                }),
                createPartExtractionServiceRequest: builder.mutation<any, PartExtractionServiceRequestDTO>({
                    query: ({
                                guildId,
                                itemListIds,
                                displaySetIds,
                                includesPrivateAttributes,
                                format,
                                frequency,
                                extractionPath,
                                workflowConfigurationId
                            }) => ({
                        url: 'serviceRequests/part-extraction',
                        method: 'POST',
                        data: {
                            guildId,
                            itemListIds,
                            displaySetIds,
                            includesPrivateAttributes,
                            format,
                            ...(frequency !== ExtractionFrequencyEnum.ONE_TIME && {
                                frequency: frequency
                            }),
                            extractionPath,
                            workflowConfigurationId
                        }
                    }),
                    transformResponse: (response: any) => response.data,
                    transformErrorResponse: (response: any) => generateErrorMessage(response)
                }),
                getServiceRequestFile: builder.mutation<any, IdType>({
                    queryFn: async (serviceRequestId, {dispatch}, extraOptions, baseQuery) => {
                        try {
                            const serviceRequestFileResult = await baseQuery({
                                url: `serviceRequests/${serviceRequestId}/file`,
                                method: 'GET'
                            })

                            const file = (serviceRequestFileResult.data as GenericRequestResponse<FileDTO | null>)?.data

                            if (!file) {
                                dispatch(setSnackbarMessage('File is not available.'))
                                return {error: 'File is not available.'}
                            }
                            await saveFileFromBlobStorage(file)

                            return {data: file}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage('File is not available.'))
                            return {error: error?.response}
                        }
                    }
                }),
                getServiceRequestFileLink: builder.mutation<any, IdType>({
                    queryFn: async (serviceRequestId, {dispatch}, extraOptions, baseQuery) => {
                        try {
                            const getServiceRequestFileLinkEndpoint = await baseQuery({
                                url: `serviceRequests/${serviceRequestId}/extraction-download`,
                                method: 'GET'
                            })

                            const file = (
                                getServiceRequestFileLinkEndpoint.data as GenericRequestResponse<FileDTO | null>
                            )?.data

                            if (!file) {
                                dispatch(setSnackbarMessage('File is not available.'))
                                return {error: 'File is not available.'}
                            }
                            await saveFileFromBlobStorage(file)

                            return {data: file}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage('File is not available.'))
                            return {error: error?.response}
                        }
                    }
                }),
                getServiceRequestQuotationFile: builder.mutation<any, IdType>({
                    queryFn: async (serviceRequestId, {dispatch}, extraOptions, baseQuery) => {
                        try {
                            const getServiceRequestFileLinkEndpoint = await baseQuery({
                                url: `serviceRequests/${serviceRequestId}/quotation-file`,
                                method: 'GET'
                            })

                            const file = (
                                getServiceRequestFileLinkEndpoint.data as GenericRequestResponse<FileDTO | null>
                            )?.data

                            if (!file) {
                                dispatch(setSnackbarMessage('File is not available.'))
                                return {error: 'File is not available.'}
                            }
                            await saveFileFromBlobStorage(file)

                            return {data: file}
                        } catch (error: any) {
                            dispatch(setSnackbarMessage('File is not available.'))
                            return {error: error?.response}
                        }
                    }
                }),
                resetRecurringExtraction: builder.mutation<IdType, IdType>({
                    query: (serviceRequestId) => ({
                        url: `serviceRequests/serviceRequest/${serviceRequestId}/recurring-extraction/reset`,
                        method: 'POST'
                    }),
                    transformResponse: (response: any) => response.data,
                    invalidatesTags: [SERVICE_REQUESTS_TAG]
                })
            }
        }
    })

export const {
    useResetRecurringExtractionMutation,
    useCreateDefaultServiceRequestMutation,
    useGetGuildServiceRequestsQuery,
    useGetGuildServiceRequestsForApproverQuery,
    useGetUserServiceRequestsQuery,
    useAcceptQuotationMutation,
    useRejectQuotationMutation,
    useAcceptServiceRequestMutation,
    useRejectServiceRequestMutation,
    useCreateItemMonitoringServiceRequestMutation,
    useCreateAccessToInformationPackageServiceRequestForListMutation,
    useCreateAccessToInformationPackageServiceRequestForItemMutation,
    useCreateCADModelServiceRequestMutation,
    useCreateFreeTextServiceRequestMutation,
    useUpdateFreeTextServiceRequestMutation,
    useCreatePrivateArtifactServiceRequestMutation,
    useUpdatePrivateArtifactServiceRequestMutation,
    useCreatePartExtractionServiceRequestMutation,
    useGetServiceRequestFileMutation,
    useGetServiceRequestFileLinkMutation,
    useGetServiceRequestQuotationFileMutation
} = serviceRequestApi
