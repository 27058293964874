import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import ItemDetailsContent from '../itemDetailsContent/ItemDetailsContent'
import './ItemDetailsPage.scss'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'
import {NoData} from '../../../genericComponents/NoData'
import {selectCurrentUser} from '../../../user/rtkUserApi'
import {useSelector} from 'react-redux'
import {useGetItemDetailsQuery} from '../../rtkItemApi'
import {useCurrentGuildContext, useItemDetailView} from '../../../util/routing'
import ItemDetailsHeader from '../itemDetailsHeader/ItemDetailsHeader'
import BreadcrumbsSection from '../BreadcrumbSection/BreadcrumbsSection'
import {DisplaySetTabsWrapperComponent} from '../../../displaySet/DisplaySetTabsWrapperComponent'
import {DisplaySetProvider} from '../../../displaySet/DisplaySetContext'
import {sanitizeAndSortDisplaySetsAndConfiguration} from '../../../user/model/DisplaySetConfiguration'
import {DisplaySet} from '../../../displaySet/model/DisplaySet'
import {ItemDetailView} from './ItemDetailToggleButton'
import {DisplaySetOrdered} from '../../../user/model/DisplaySetOrdered'


const ItemDetailsPage = () => {
    const params = useParams()
    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)

    const guildId = currentGuildContext?.guildId
    const itemId = params?.itemId
    const {data: item, isFetching, isError} = useGetItemDetailsQuery({itemId, guildId}, {skip: !user})

    const [displaySetsOrdered, setDisplaySetsOrdered] = useState<DisplaySetOrdered[] | undefined>()
    const [selectedItemDetailView, setSelectedItemDetailView] = useItemDetailView()

    const handleDisplaySetsChange = (newDisplaySets: DisplaySet[] | undefined) => {
        let sanitizedAndSortedDisplaySets = sanitizeAndSortDisplaySetsAndConfiguration(newDisplaySets, undefined)
        if(selectedItemDetailView === ItemDetailView.CUSTOMISED) {
            sanitizedAndSortedDisplaySets = sanitizeAndSortDisplaySetsAndConfiguration(newDisplaySets, user?.displaySetConfigurations)
        }
        setDisplaySetsOrdered(sanitizedAndSortedDisplaySets)

    }

    useEffect(() => {
        handleDisplaySetsChange(item?.displaySets)
    }, [item?.displaySets, selectedItemDetailView])

    if (isFetching) return <LoadingSpinner/>

    if (!item || isError) return <NoData/>

    return (
        <div className="item-details-page details-page">
            <DisplaySetProvider displaySets={displaySetsOrdered}>
                <BreadcrumbsSection
                    classificationId={item.technical.id}
                    partNumber={item?.partNumber}
                />

                <ItemDetailsHeader itemDetails={item}/>


                <DisplaySetTabsWrapperComponent displaySets={displaySetsOrdered}
                                                defaultTabName={'Item Details'}
                                                selectedItemDetailView={selectedItemDetailView}
                                                setSelectedItemDetailView={setSelectedItemDetailView}>
                    <ItemDetailsContent item={item} selectedItemDetailView={selectedItemDetailView}/>
                </DisplaySetTabsWrapperComponent>
            </DisplaySetProvider>
        </div>
    )
}

export default ItemDetailsPage
