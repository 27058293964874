import React from 'react'
import {FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material'
import {KeyboardArrowDown} from '@mui/icons-material'
import {useFormikContext} from 'formik'
import {Label} from '../../../genericComponents/typographie/Label'
import {useGetAccessibleWorkflowsQuery} from '../../../guild/rtkGuildApi'
import {useCurrentGuildContext} from '../../../util/routing'
import {ServiceEnum} from '../../model/ServiceEnum'
import {ServiceRequestWorkflowInput} from './ServiceRequestWorkflowInput'

export const WorkflowSelector = ({ service }: {
    service: ServiceEnum
}) => {

    const formik = useFormikContext<ServiceRequestWorkflowInput>()

    const [currentGuildContext] = useCurrentGuildContext()

    const { data: workflows } = useGetAccessibleWorkflowsQuery({
        guildId : currentGuildContext?.guildId,
        service: service
    })

    const handleWorkflowChange = (event: SelectChangeEvent) => {
        formik.setFieldValue('selectedWorkflowId', event.target.value)
    }

    return (
        <FormControl>
            <Label required={true} className="label-format">
                Workflow
            </Label>
            <Select
                size="small"
                className="format-selector"
                displayEmpty
                data-testid="workflow-configuration-dropdown-selector"
                id="workflow-configuration-list"
                required
                IconComponent={KeyboardArrowDown}
                value={formik.values.selectedWorkflowId}
                onChange={handleWorkflowChange}
            >
                {workflows?.map((workflow) => (
                    <MenuItem key={workflow.id} value={workflow.id} data-cy={`select-option-${workflow.name}`}>
                        {workflow.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}