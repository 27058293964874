import './ItemDetailsHeader.scss'
import {SimpleItem} from '../../model/SimpleItem'
import React, { useState } from 'react'
import ItemTypeChip from '../../ItemTypeChip'
import ItemDetailsHeaderContent from './ItemDetailsHeaderContent'
import AddToListButton from '../../../genericComponents/AddToListButton'
import ItemSearchModal from '../../../search/searchResultPage/components/AddItemToListModal/AddItemToListModal'

const ItemDetailsHeader = ({itemDetails}: {itemDetails: SimpleItem}) => {
    const [addTolist, setAddToList] = useState(false)

    return (
        <div className="item-details-page-section details-page-section">
            <div className="pre-header">
                <div className="pre-header-information">
                    <span className="pre-header-label">Item Type</span>
                    <span className="pre-header-value">
                        <ItemTypeChip itemTypeLabel={itemDetails.type.label} />
                    </span>
                </div>
                <div className="divider" />
                <div className="pre-header-information">
                    <span className="pre-header-label">Item Nature</span>
                    <span className="pre-header-value">{itemDetails.nature.label}</span>
                </div>
                <div className="divider" />
                <div className="pre-header-information">
                    <span className="pre-header-label">Definition Level</span>
                    <span className="pre-header-value">{itemDetails.function.label}</span>
                </div>
                <div className="add-to-list">
                    <AddToListButton onClick={() => setAddToList(true)} tooltip="Add to List"/>
                </div>
            </div>
            <div className="content-section">
                <ItemDetailsHeaderContent selectedItemDetails={itemDetails} />
            </div>
            {addTolist &&
            <ItemSearchModal closeModal={() => setAddToList(false)} itemList={[itemDetails]}/>}
        </div>
    )
}

export default ItemDetailsHeader
