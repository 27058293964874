import React, {ReactNode} from 'react'
import {DisplaySetComponent} from './DisplaySetComponent'
import {SimpleItem} from '../item/model/SimpleItem'
import {DisplaySetOrdered} from '../user/model/DisplaySetOrdered'

const getDisplaySetComponent = (displaySet: DisplaySetOrdered, depth: number, childrenNodes: ReactNode, parentItem?: SimpleItem,): ReactNode => {
    const map: Map<number, ReactNode> = new Map([
        [0, <DisplaySetTab children={childrenNodes}/>],
        [1, <DisplaySetTable displaySet={displaySet} parentItem={parentItem} children={childrenNodes}/>]
    ])

    return map.get(depth) ?? <></>
}

export const DisplaySetTreeComponent = ({displaySet, parentItem, depth = 0}: {
    displaySet: DisplaySetOrdered,
    parentItem?: SimpleItem,
    depth?: number
}) => {
    if ((!displaySet.children || displaySet.children?.length <= 0) && displaySet.displaySet.attributes?.length <= 0) return <></>

    const childrenNodes = displaySet.children?.map(childDisplaySet =>
        !childDisplaySet.disable ? <DisplaySetTreeComponent
            displaySet={childDisplaySet} key={childDisplaySet.id}
            parentItem={parentItem}
            depth={depth + 1}/> : null
    )

    const displaySetComponent = getDisplaySetComponent(displaySet, depth, childrenNodes, parentItem) ?? <></>
    return <>
        {displaySetComponent}
    </>
}

const DisplaySetTab = ({children}: {
    children?: ReactNode
}) => {
    return <div data-testid="test-top-level-display-set">
        <div className="simple-attributes" data-testid="test-details-simple-attribute-container">
            {children}
        </div>
    </div>
}

const DisplaySetTable = ({displaySet, parentItem, children}: {
    displaySet: DisplaySetOrdered,
    parentItem?: SimpleItem,
    children?: ReactNode
}) => {
    return <DisplaySetComponent key={displaySet?.id ?? Math.random()}
                                displaySet={displaySet} parentItem={parentItem}/>
}
