import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(relativeTime)
dayjs.extend(utc)

const timestampFormat = 'YYMMDDHHmm'

export const formatDate = (formattedDate: number[]): string => {
    return dayjs(createDate(formattedDate)).format('DD/MM/YYYY')
}

export const isAfterCurrentDatePlusMonths = (dateToCompare: number[], numberOfMonthToAdd: number): boolean => {
    return dayjs().add(numberOfMonthToAdd, 'M').isAfter(createDate(dateToCompare))
}

export const formatTime = (dateToDisplay: number[]): string => {
    return dayjs(createDate(dateToDisplay)).utcOffset(2, false).format('HH:mm')
}

export const formatUnixTime = (dateToDisplay: number[]): string => {
    return dayjs.utc(createDate(dateToDisplay)).local().format('LT')
}

export const fromNow = (dateToDisplay: number[]): string => {
    return dayjs.utc(createDate(dateToDisplay)).fromNow().toString()
}

export const convertToUnix = (date: number[]): number => {
    return dayjs(createDate(date)).unix()
}

export const generateTimestamp = (): string => {
    const currentUtc = dayjs.utc()
    return currentUtc.format(timestampFormat)
}

const createDate = (dateToDisplay: number[]) => {
    return new Date(...(dateToDisplay as [number, number, number, number, number, number]))
}

export const formatMilliseconds = (entryDate: number[]) => {
    //The milliseconds value is not well formatted. As it is not used for display, it can be ignored.
    if (entryDate.length >= 6) {
        entryDate[6] = 0
    }
    return entryDate
}

export const formatMonth = (dateToDisplay: number[]) => {
    // dayjs accepts numbers from 0 to 11
    dateToDisplay[1] = dateToDisplay[1] - 1
}
