import React from 'react'
import {withAITracking} from '@microsoft/applicationinsights-react-js'
import {startAppInsight} from './util/tracking/appInsight'
import {RequestInterceptor} from './util/axiosInterceptor'
import reactPlugin from './util/reactPlugin'
import './App.scss'
import {useMatchedRoute} from './util/routing'
import {Snackbar} from './genericComponents/Snackbar'
import {Helmet} from 'react-helmet'
import {initHotJar} from './util/tracking/hotjar'

startAppInsight()

const App: React.FC<any> = ({children}) => {
    const DEFAULT_PORTAL_TITLE = 'DATX '

    initHotJar()
    
    const routes = useMatchedRoute()
    const pageTitle = routes?.[0]?.route.options?.pageTitle
    const getPageHeader = () => {
        const header = DEFAULT_PORTAL_TITLE
        if (!pageTitle) return header

        return header + ' - ' + pageTitle
    }

    return (
        <RequestInterceptor>
            <div className="main-app-container" data-testid="main-app-container">
                <Helmet>
                    <title>{getPageHeader()}</title>
                </Helmet>
                {children}
                <Snackbar/>
            </div>
        </RequestInterceptor>
    )
}
export default withAITracking(reactPlugin, App)
