import {ApplicationInsights} from '@microsoft/applicationinsights-web'
import reactPlugin from '../reactPlugin'
import {getCookieConsentFor} from '../../Cookies/cookiesUtils'
import {CookiesCategory, CookieVendor} from '../../Cookies/CookiesTypes'

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_APPINSIGHT_KEY,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        extensions: [reactPlugin],
    },
})

export const setAppInsightUser = (username: string | undefined) => {
    appInsights.setAuthenticatedUserContext(username ?? 'unknown')
}

const setAppInsightCookiePermission = () => {
    appInsights.config.disableCookiesUsage = !getCookieConsentFor(CookiesCategory.PerformanceCookies, CookieVendor.MicrosoftApplicationInsights)
}

export const disableAppInsightCookie = () => {
    appInsights.config.disableCookiesUsage = true
    appInsights.getCookieMgr().purge('ai_user')
    appInsights.getCookieMgr().purge('ai_session')

}
export const activateAppInsightCookie = () => {
    appInsights.config.disableCookiesUsage = false
}

export const startAppInsight = () => {
    setAppInsightCookiePermission()
    appInsights.loadAppInsights()
    appInsights.addTelemetryInitializer((envelope) => {
        if (envelope.tags != null) {
            envelope.tags['ai.cloud.role'] =
                process.env.REACT_APP_APPINSIGHT_ROLE
            envelope.tags['ai.cloud.roleInstance'] =
                process.env.REACT_APP_APPINSIGHT_ROLE
        }
    })
}

export default appInsights